/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDrDT03jgXqisEZ1f5eo5hkD5LAIld3Hq4",
  "appId": "1:246861685032:web:614ca22dbe29cce42cb01d",
  "authDomain": "schooldog-i-catoosa-ga.firebaseapp.com",
  "measurementId": "G-QZWN5BFHQL",
  "messagingSenderId": "246861685032",
  "project": "schooldog-i-catoosa-ga",
  "projectId": "schooldog-i-catoosa-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-catoosa-ga.appspot.com"
}
